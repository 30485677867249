/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 15:59:50
 * @Module: 视频详情
 */
 <template>
  <div class="detail"
       v-if="detailData">
    <div class="videoDetail-title">{{detailData.moment_content.title}}</div>
    <detail-userinfo :data="detailData" />
    <div class="videoDetail-content">
      <video :src="detailData.moment_content.play_address"
             controls
             width="660px"
             style="background:#000;"
             :poster="detailData.moment_content.video_cover_info?detailData.moment_content.video_cover_info.src:false"
             height="370px"></video>
      <div class="videoDetail-content-text">{{detailData.moment_content.content}}</div>
    </div>
    <tip-off :data="detailData"
             @onLike="onLike"
             @onTipOff="onTipOff" />
    <column :data="detailData" />
    <comment :data="detailData" />
    <recommend :data="detailData" />
    <menu-list :data="detailData" />
  </div>
</template>
 <script>
import detailMixins from "./detailMixins"
export default {
  components: {},
  mixins: [detailMixins],
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
@import url("./style.scss");
.videoDetail-title {
  font-size: 30px;
  margin-top: 16px;
}
.videoDetail-content-text {
  font-size: 16px;
  color: #333;
  margin-top: 14px;
  line-height: 25px;
}
</style>